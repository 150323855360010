import { useForm } from 'react-hook-form';
import { LOGIN_ACTION, useAuth } from 'context/user-context';
import { useState, useEffect } from 'react';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { createAccount, logIntoAccount, registerGuest } from 'api/user';
import { Transition } from '@headlessui/react';
import LoadingSpinner from 'components/LoadingSpinner';
import _ from 'lodash';
import { PIXEL_EVENTS, trackEvent } from 'services/analytics';

const FORM_TYPES = Object.freeze({
  CreateAccount: 'createAccount',
  Login: 'login',
  Guest: 'guest',
});

const accountSectionButtons = Object.freeze({
  createAccount: {
    label: 'Create Account',
    formType: FORM_TYPES.CreateAccount,
  },
  login: { label: 'Log In', formType: FORM_TYPES.Login },
  guest: { label: 'Checkout as a Guest', formType: FORM_TYPES.Guest },
});

function AuthForm({ priceForAnalytics }) {
  const [formToDisplay, setFormToDisplay] = useState();
  const [user] = useAuth();
  const { isLoggedIn, userName, isGuest, deliveryEmail } = user;

  useEffect(() => {
    if (isLoggedIn) {
      setFormToDisplay(undefined);
    }
  }, [isLoggedIn]);

  return (
    <div className="mt-6">
      <ul className="flex justify-between mb-10 w-full">
        {isLoggedIn ? (
          <div className="text-blue-light">
            {isGuest ? (
              <div>
                <div>{userName} checked out as guest</div>
                <div>Deliver to: {deliveryEmail}</div>
              </div>
            ) : (
              `Logged in as ${userName}`
            )}
          </div>
        ) : (
          <div className="flex gap-4 items-start justify-between w-full">
            {Object.values(accountSectionButtons).map((item) => {
              const { formType } = item;
              const isSelected = formToDisplay === formType;
              return (
                <li key={item.label}>
                  <button
                    disabled={isLoggedIn}
                    onClick={() => setFormToDisplay(formType)}
                    className={`text-btn h-14 ${
                      isSelected
                        ? 'border-none'
                        : 'bg-grey-darkest text-opacity-25 text-white '
                    }`}
                  >
                    <span
                      className={`min-h-9 text-tiny md:text-lg lg:underline font-HelveticaBold ${
                        isSelected
                          ? 'lg:text-blue-light decoration-blue-light'
                          : 'text-white'
                      }`}
                    >
                      {item.label}
                    </span>
                  </button>
                </li>
              );
            })}
          </div>
        )}
      </ul>
      <Transition
        as={'div'}
        show={Boolean(formToDisplay)}
        enter="transform transition duration-700"
        enterFrom="opacity-0 "
        enterTo="opacity-100 "
        leave="transform duration-1000 transition ease-in-out"
        leaveFrom="opacity-100 "
        leaveTo="opacity-0 scale-95 "
      >
        {' '}
        <Form
          formType={formToDisplay}
          priceForAnalytics={priceForAnalytics}
        />{' '}
      </Transition>
    </div>
  );
}

export default AuthForm;

const Input = ({
  label,
  id,
  register,
  type,
  minLength = 1,
  validate,
  required = true,
  errors,
}) => {
  const errorMessages = {
    minLength: `must be at least ${minLength} characters`,
    passwordMatch: 'passwords must match',
  };

  return (
    <div>
      <label
        htmlFor={id}
        className="blocktext-tiny font-medium text-gray-700 font-HelveticaBold"
      >
        {label}
      </label>
      <div className="mt-1">
        <input
          id={id}
          {...register(id, {
            minLength,
            validate,
          })}
          type={type}
          required={required}
          autoComplete="true"
          className="mt-1 focus:ring-blue-light focus:border-blue-light w-5/6  md:max-w-32 max-h-8 lg:max-w-72 lg:max-h-16 border-2 border-white rounded-md text-grey-darkest bg-grey-lightest"
        />
        {errors[id] && <div>{errorMessages[errors[id]?.type]}</div>}
      </div>
    </div>
  );
};

const Form = ({ formType, priceForAnalytics, className }) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState();

  const [, dispatch] = useAuth();

  const INPUT_IDS = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    EMAIL: 'email',
    PASSWORD: 'password',
    CONFIRM_PASSWORD: 'confirm-password',
  };

  const submitLogin = async (formValues) => {
    trackEvent({
      event: PIXEL_EVENTS.INITIATE_CHECKOUT,
      properties: {
        value: priceForAnalytics,
      },
    });
    setIsLoading(true);
    let { email, password, firstName, lastName } = formValues;
    try {
      let loginRes = await formsConfig[formType].submitFunction({
        email,
        password,
        firstName,
        lastName,
      });
      const {
        firstName: firstNameFromDB,
        lastName: lastNameFromDB,
        voipId,
        userId,
      } = loginRes;
      console.log({ loginRes });

      if (_.isNil(firstName)) {
        firstName = firstNameFromDB;
      }
      if (_.isNil(lastName)) {
        lastName = lastNameFromDB;
      }
      dispatch({
        type: LOGIN_ACTION,
        userName: `${firstName} ${lastName}`,
        isGuest: formType === FORM_TYPES.Guest,
        deliveryEmail: email,
        userId,
        voipId,
      });
    } catch (error) {
      const loginType = accountSectionButtons[formType].label;
      console.log({ error });
      setLoginError(
        `an error occurred while trying to ${loginType}.
Error info: ${error.message}.
Please try again`
      );
      setIsLoading(false);
    }
  };

  const formsConfig = {
    [FORM_TYPES.CreateAccount]: {
      submitFunction: createAccount,
      inputs: [
        { label: 'First Name', id: INPUT_IDS.FIRST_NAME, type: 'text' },
        { label: 'Last Name', id: INPUT_IDS.LAST_NAME, type: 'text' },
        { label: 'Email', id: INPUT_IDS.EMAIL, type: 'email' },
        {
          label: 'Create a Password',
          id: INPUT_IDS.PASSWORD,
          type: 'password',
          minLength: 6,
        },
        {
          label: 'Confirm the Password',
          id: INPUT_IDS.CONFIRM_PASSWORD,
          type: 'password',
          minLength: 6,
          validate: {
            passwordMatch: (value) => getValues('password') === value,
          },
        },
      ],
    },
    [FORM_TYPES.Login]: {
      submitFunction: logIntoAccount,
      inputs: [
        { label: 'Email', id: INPUT_IDS.EMAIL, type: 'email' },
        {
          label: 'Password',
          id: INPUT_IDS.PASSWORD,
          type: 'password',
          minLength: 6,
        },
      ],
    },
    [FORM_TYPES.Guest]: {
      submitFunction: registerGuest,
      inputs: [
        { label: 'First Name', id: INPUT_IDS.FIRST_NAME, type: 'text' },
        { label: 'Last Name', id: INPUT_IDS.LAST_NAME, type: 'text' },
        { label: 'Delivery Email', id: INPUT_IDS.EMAIL, type: 'email' },
      ],
    },
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : formType ? (
    <div className={`${className} `}>
      {loginError ? (
        <div
          className="capitalize my-2"
          style={{ color: 'red', whiteSpace: 'pre-wrap' }}
        >
          {loginError}
        </div>
      ) : null}
      <form onSubmit={handleSubmit(submitLogin)} action="#">
        <div className="grid grid-cols-2 gap-2  mb-5">
          {formsConfig[formType].inputs.map((input) => (
            <Input
              key={input.label}
              {...input}
              register={register}
              errors={errors}
            />
          ))}
        </div>
        <button
          type="submit"
          className="font-HelveticaBold flex items-center text-btn w-24 md:text-lg md:w-32 xl:w-auto lg:text-blue-light h-10"
        >
          Next
          <ArrowRightIcon className="h-6 w-6 ml-4" />
        </button>
      </form>
    </div>
  ) : null;
};
