import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useSkillerDMPrices } from 'utils/skiller';
import { PIXEL_EVENTS, trackEvent } from 'services/analytics';

export const OrderFormTypes = Object.freeze({
  video: 'video',
  voice: 'voice',
  text: 'text',
});

const labels = {
  [OrderFormTypes.video]: {
    prefix: 'Book a',
    suffix: 'for',
  },
  [OrderFormTypes.voice]: {
    prefix: 'Get a',
    suffix: 'message for',
  },
  [OrderFormTypes.text]: {
    prefix: 'Request a',
    suffix: 'message for',
  },
};

const Label = ({ type, price }) => {
  if (!type) {
    return null;
  }

  return (
    <>
      {labels[type].prefix}
      <span className="text-white font-HelveticaBold mx-1">{type}</span>
      {labels[type].suffix} ${price}
    </>
  );
};
function OrderServiceButtons({ orderFormType, minified, className }) {
  const history = useHistory();
  const { nickname: skillerNickName } = useParams();

  const { videoPrice, voicePrice, textPrice, isLoading } = useSkillerDMPrices();

  if (isLoading) {
    return null;
  }

  const dmPrices = { video: videoPrice, voice: voicePrice, text: textPrice };

  return (
    <div className={className} style={{ maxWidth: 720 }}>
      {Object.values(OrderFormTypes).map((type) => (
        <button
          className={`primary-btn pt-4 pb-3 px-1 mb-4 md:mb-2 font-HelveticaLight text-tiny md:text-base  ${
            orderFormType === undefined || type === orderFormType
              ? 'primary-btn border-none'
              : 'bg-grey-darkest text-opacity-50 text-grey-lightest'
          } ${minified ? 'w-24 h-16 md:w-44 md:h-12' : 'w-full'}
              `}
          key={type}
          onClick={() => {
            trackEvent({
              event: PIXEL_EVENTS.ADD_TO_CART,
              action: 'button-click',
            });
            history.push(`/${skillerNickName}/order?orderform=${type}`);
          }}
        >
          <>
            {minified ? (
              <span className="font-HelveticaBold">
                {type.charAt(0).toUpperCase() + type.slice(1)} Message $
                {dmPrices[type]}
              </span>
            ) : (
              <Label type={type} price={dmPrices[type]} />
            )}
          </>
        </button>
      ))}
    </div>
  );
}

OrderServiceButtons.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      pricedLabel: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  orderFormType: PropTypes.oneOf(Object.values(OrderFormTypes)),
};

export default OrderServiceButtons;
