import React from 'react';
import PropTypes from 'prop-types';

function AdditionalServices({ additionalServicesData }) {
  return (
    <div className="additional-services">
      <ul
        className="last: mb-3 lg:last:mb-6 font-HelveticaLight text-grey-dark list-none list-outside pl-4"
        role="none"
      >
        {additionalServicesData.map((service) => (
          <li key={service.label}>
            <h4 className="m-auto text-sm lg:text-base">— {service.label}</h4>
          </li>
        ))}
      </ul>
    </div>
  );
}

AdditionalServices.propTypes = {
  additionalServicesData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ),
};

export default AdditionalServices;
