import SkillerProfileImg from 'components/SkillerProfileImg/SkillerProfileImg';
import { useEffect } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useSkiller } from 'utils/skiller';

function SkillerIntro({ withProfileImg = true, onLoad, mainScreen }) {
  const { skiller, isLoading } = useSkiller();

  useEffect(() => {
    if (!withProfileImg) {
      onLoad();
    }
  }, [withProfileImg, onLoad]);

  if (isLoading) {
    return null;
  }

  const {
    first_name: firstName,
    last_name: lastName,
    skiller_information,
  } = skiller;

  if (!skiller_information) {
    return null;
  }

  const { nickname, profile_information } = skiller_information;
  const {
    occupation_title: occupationTitle,
    short_description: shortDescription,
  } = profile_information;

  return (
    <>
      <div className="flex flex-row-reverse gap-8 lg:gap-0 lg:flex-row justify-between items-center md:justify-start ">
        <div className="flex xl:flex-row w-full flex-row-reverse md:mr-auto">
          {withProfileImg ? (
            <SkillerProfileImg
              className={`md:mr-8 lg:mr-10 xl:mr-14 md:mb-auto ml-auto ${
                mainScreen ? 'xl:hidden' : ''
              }`}
              onLoad={onLoad}
            />
          ) : null}
          <div className="leading-none text-blue-light flex-grow flex flex-col md:gap-2 w-3/5 ">
            <div className="flex justify-between items-start flex-col">
              <h1 className="text-xl lg:text-5xl mb-1 font-HelveticaBold">{`${firstName} ${lastName}`}</h1>
              <h2 className="text-lg lg:text-2xl mb-2 font-HelveticaBold">
                {occupationTitle}
              </h2>
              <p className="inline-block font-HelveticaLight text-tiny text-grey-dark leading-6">
                {shortDescription}
                <span className="text-grey-lightest"> @{nickname}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SkillerIntro;
