import React, { useEffect, useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import Page from 'components/Page';
import OrderServiceButtons from 'components/OrderServiceButtons';
import AdditionalServices from 'components/AdditionalServices';
import { redirectPage } from 'utils/redirect';
import { ANDROID_APP_URL, IOS_APP_URL } from 'utils/constants';
import SkillerIntro from 'components/SkillerIntro';
import SkillerProfileImg from 'components/SkillerProfileImg/SkillerProfileImg';
import { useSkiller } from 'utils/skiller';
import _ from 'lodash';
import mutedIcon from 'assets/images/muted-icon.svg';
import unmutedIcon from 'assets/images/unmuted-icon.svg';
import { PIXEL_EVENTS, trackEvent } from 'services/analytics';
import { Helmet } from 'react-helmet';

function SkillerScreen() {
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const [profileImgLoaded, setProfileImgLoaded] = useState(false);
  const [headerLoaded, setHeaderLoaded] = useState(false);

  const { skiller, isLoading } = useSkiller();

  const { first_name: skillerName, nickname: skillerNickname } = skiller;

  useEffect(() => {
    trackEvent({ event: PIXEL_EVENTS.VIEW_CONTENT, action: 'view' });
  }, []);

  const additionalServicesData = [
    {
      label: `Video call ${skillerName}`,
    },
    {
      label: `Subscribe for exclusive content updates from ${skillerName}`,
    },
  ];

  return (
    <>
      <Page
        loading={
          isLoading ||
          !mediaLoaded ||
          (!isMobile && (!profileImgLoaded || !headerLoaded))
        }
      >
        <Helmet>
          <meta property="og:title" content={`Skiller_${skillerName}`} />
          <meta
            property="og:url"
            content={`https://vanywhere.com/${skillerNickname}`}
          />
          <meta property="product:brand" content={skillerName} />
        </Helmet>

        {isMobileOnly ? (
          <>
            <SkillerMedia
              onLoad={() => {
                setMediaLoaded(true);
              }}
            />
            <Page.Header onLoad={() => setHeaderLoaded(true)} mainScreen />
          </>
        ) : null}
        <Page.Main className="grid grid-cols-1 gap-8 xl:grid-cols-5 mb-5 lg:mb-2.5 md:mx-40 2xl:mx-0 3xl:mx-40 ">
          {!isMobileOnly ? (
            <SkillerMedia
              onLoad={() => {
                setMediaLoaded(true);
              }}
            />
          ) : null}
          <div className="px-5 md:px-0 xl:col-span-2 flex flex-col gap-4 md:gap-10 lg:gap-0">
            {!isMobileOnly ? (
              <SkillerIntro
                withProfileImg={!isMobileOnly}
                onLoad={() => {
                  setHeaderLoaded(true);
                }}
                mainScreen
              />
            ) : null}
            <OrderServiceButtons className="flex flex-col mt-5 text-white" />
            <div>
              <h3 className="text-blue-light text-sm lg:text-xl">
                100% response guaranteed
              </h3>
              <h3 className="text-grey-lightest text-sm lg:text-xl mb-4 lg:mb-10 font-HelveticaLight">
                you are guaranteed to get a response within 7 days or your money
                back
              </h3>
              <div className="additional-options-container rounded-2xl bg-gradient-to-t from-grey-medium bg-opacity-50">
                <h3 className="border-b border-grey-light mb-2 lg:mb-4 lg:pl-5 text-grey-darkest text-sm lg:text-xl">
                  Additional options on the Vanywhere app
                </h3>
                <AdditionalServices
                  additionalServicesData={additionalServicesData}
                />
                <div className="text-blue-light ">
                  <button
                    className="text-sm lg:text-xl hover:text-blue-medium ml-2.5 lg:ml-5 mr-3.5 lg:mr-7 mb-1.5 lg:mb-2.5"
                    onClick={() => redirectPage({ url: IOS_APP_URL })}
                  >
                    iOS
                  </button>
                  <button
                    className="text-sm lg:text-xl hover:text-blue-medium"
                    onClick={() => redirectPage({ url: ANDROID_APP_URL })}
                  >
                    Android
                  </button>
                </div>
              </div>
            </div>
          </div>

          <SkillerProfileImg
            className="hidden xl:block"
            onLoad={() => {
              setProfileImgLoaded(true);
            }}
          />
        </Page.Main>
      </Page>
    </>
  );
}

function SkillerMedia({ onLoad }) {
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const { skiller, isLoading } = useSkiller();

  if (isLoading || !skiller || !skiller.skiller_information) {
    return null;
  }

  const {
    skiller_information: {
      profile_information: {
        cover_video_url: coverVideoUrl,
        cover_image_url: coverImageUrl,
      },
    },
  } = skiller;
  const mediaItemHeight = isMobileOnly ? '18rem' : '26rem'; // so video and parent div will be at the same height and to use CSSinJs styling for relative div

  return (
    <div className="h-2/5 flex justify-center mb-8 xl:w-80 xl:-ml-40 4xl:-ml-24  xl:col-span-1 xl:col-start-2 px-4 md:px-0">
      {coverVideoUrl ? (
        <div className="relative" style={{ height: mediaItemHeight }}>
          <video
            autoPlay
            muted={isVideoMuted}
            loop
            playsInline // for autoplay on iPhone
            className="md:col-span-1 rounded-lg object-cover "
            style={{ height: mediaItemHeight }} // so video tag will have height and the mute button will be position at the bottom of it
            onLoadedData={onLoad}
          >
            <source src={coverVideoUrl} />
            le;wlx
          </video>
          <button
            className="rounded-lg absolute bottom-0 right-0 p-2"
            onClick={() => setIsVideoMuted((prev) => !prev)}
          >
            <img
              src={isVideoMuted ? mutedIcon : unmutedIcon}
              className="h-4 w-4 md:h-6 md:w-6"
              alt="mute icon"
            />
          </button>
        </div>
      ) : (
        <img
          src={coverImageUrl}
          className="rounded-lg object-cover"
          style={{ height: mediaItemHeight }}
          alt="Skiller profile"
          onLoad={onLoad}
        />
      )}
    </div>
  );
}

export default SkillerScreen;
