import { loginToQB, registerToQuickBlox } from 'services/quickblox';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { cloudFunctions } from 'services/firebase';
import { v4 as uuidv4 } from 'uuid';

const auth = getAuth();

export async function getUserByEmail({ userEmail }) {
  const res = await cloudFunctions.getSkillerByEmail({
    skillerEmail: userEmail,
  });
  const { data } = res;
  const { skillersInfo } = data;
  return skillersInfo;
}

export const createAccount = async ({
  email,
  password,
  firstName,
  lastName,
}) => {
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );
  return new Promise((resolve, reject) => {
    if (userCredential) {
      const { user } = userCredential;
      console.log('regisering to QB');
      try {
        registerToQuickBlox({
          email: user.email,
          firstName,
          lastName,
          callback: function (error, result) {
            if (error) {
              console.log({ error });
              reject(error);
            } else {
              const { id: voipId } = result;
              resolve({ firstName, lastName, voipId, userId: user.uid });
              cloudFunctions
                .createUser({
                  userId: user.uid,
                  email,
                  firstName,
                  lastName,
                  voipId,
                })
                .then((res) => {
                  console.log(res);
                  logIntoAccount({ email, password });
                })
                .catch((error) => {
                  reject(error);
                });
            }
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  });
};

export const logIntoAccount = async ({ email, password }) => {
  const userCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password
  );
  if (userCredential) {
    // need to login to quickblox
    const {
      first_name: firstName,
      last_name: lastName,
      voip_id: voipId,
      id: userId,
    } = await getUserByEmail({ userEmail: email });
    console.log(await getUserByEmail({ userEmail: email }));

    return new Promise((resolve, reject) => {
      try {
        loginToQB({
          email,
          callback: function (error, result) {
            if (error) {
              console.log({ error });
              reject(error);
            } else {
              resolve({ firstName, lastName, voipId, userId });
            }
          },
        });
      } catch (error) {
        console.log(error);
      }
    });
  }
};

export const registerGuest = async ({ email, firstName, lastName }) => {
  const uuid = uuidv4();
  const guestEmail = `${uuid}@vanywhereguest.com`;

  return new Promise((resolve, reject) => {
    registerToQuickBlox({
      email: guestEmail,
      firstName,
      lastName,
      callback: function (error, result) {
        if (error) {
          console.log({ error });
          reject(error);
        } else {
          const { id: voipId } = result;
          cloudFunctions
            .createGuestUser({
              userId: uuid,
              email: guestEmail,
              guestEmail: email,
              firstName,
              lastName,
              voipId,
            })
            .then((res) => {
              loginToQB({
                email: guestEmail,
                callback: function (error, result) {
                  if (error) {
                    console.log({ error });
                    reject(error);
                  } else {
                    console.log('success login to qb as guest');
                  }
                },
              });
              resolve({ firstName, lastName, voipId, userId: uuid });
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        }
      },
    });
  });
};
