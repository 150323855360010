import React, { useReducer } from "react";

const initialState = {
  isLoggedIn: false,
  isGuest: false,
  userName: "",
  userImage:
    "https://res.cloudinary.com/shimrit/image/upload/v1632322001/vanywhere-web-app/dummy_avatar_jgiss2.jpg",
  deliveryEmail: "",
  voipId: "",
};

// ACTIONS
const LOGIN_ACTION = "LOGIN_ACTION";
const LOGOUT_ACTION = "LOGOUT_ACTION";

export { LOGIN_ACTION, LOGOUT_ACTION };

// REDUCER
const authReducer = (state, action) => {
  switch (action.type) {
    case LOGIN_ACTION:
      return {
        ...state,
        isLoggedIn: true,
        userName: action.userName,
        isGuest: action.isGuest,
        deliveryEmail: action.deliveryEmail,
        userId: action.userId,
        voipId: action.voipId,
      };
    case LOGOUT_ACTION:
      return {
        ...state,
        isLoggedIn: initialState.isLoggedIn,
        userName: initialState.userName,
      };
    default:
      throw new Error();
  }
};

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [store, dispatch] = useReducer(authReducer, initialState);

  return <AuthContext.Provider value={[store, dispatch]} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used in a AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
