import { useAuth } from 'context/user-context';
import React from 'react';
import { useSkiller, useSkillerDMPrices } from 'utils/skiller';

export const OrderSummary = ({
  orderFormType,
  serviceFee = 0.05,
  className,
  orderData,
  setTotalOrderPrice,
}) => {
  const [user] = useAuth();
  const { isGuest } = user;
  const { videoPrice, voicePrice, textPrice } = useSkillerDMPrices();
  const dmPrices = { video: videoPrice, voice: voicePrice, text: textPrice };
  const { skiller, isLoading } = useSkiller();

  if (isLoading) {
    return null;
  }

  const { expressCheckoutPrice } = skiller;

  const deliveryType = orderData.delivery;

  let SummaryData = [
    { title: `Vanywhere ${orderFormType}`, price: dmPrices[orderFormType] },
    {
      title: `${
        deliveryType === 'expressDelivery' ? 'Expedited' : 'Standard'
      } delivery`,
      price: deliveryType === 'expressDelivery' ? expressCheckoutPrice : 0,
    },
    { title: `Service fee`, price: dmPrices[orderFormType] * serviceFee },
  ];

  if (!isGuest) {
    SummaryData.push({
      title: `Vanywhere user service fee waived`,
      price: -(dmPrices[orderFormType] * serviceFee),
      titleClassName: 'text-grey-light font-HelveticaLight italic ',
    });
  }

  const totalOrderPrice = SummaryData.reduce(
    (acc, curr) => acc + curr.price,
    0
  );
  setTotalOrderPrice(totalOrderPrice);

  SummaryData.push({
    title: 'Total',
    price: totalOrderPrice,
    titleClassName: 'font-HelveticaBold text-2xl',
    priceClassName: 'font-HelveticaBold text-2xl',
  });

  return (
    <div className={`${className} flex flex-col gap-2`}>
      <h3>Summary</h3>
      {SummaryData.map((item) => {
        return <PriceLine {...item} key={item.title} />;
      })}
    </div>
  );
};

const PriceLine = ({ title, price, titleClassName, priceClassName }) => {
  return (
    <div className="flex justify-between">
      <div
        className={
          titleClassName
            ? titleClassName
            : `font-HelveticaLight text-grey-lightest`
        }
      >
        {title}
      </div>
      <div
        className={
          priceClassName
            ? priceClassName
            : `font-HelveticaLight text-grey-lightest`
        }
      >
        {price < 0 ? '-' : ''}${Math.abs(price)}
      </div>
    </div>
  );
};
