import React from "react";
import { database, dbRef, cloudFunctions } from "services/firebase";

const FirebaseContext = React.createContext();

function FirebaseProvider(props) {
  const firebase = { database, dbRef, cloudFunctions };
  return <FirebaseContext.Provider value={firebase} {...props} />;
}

function useFirebase() {
  const context = React.useContext(FirebaseContext);

  if (!context) {
    throw new Error("useFirebase must be used in a FirebaseProvider");
  }

  return context;
}

export { FirebaseProvider, useFirebase };
