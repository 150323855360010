import React from 'react';
import logo from 'assets/images/Logo/mini_logo.svg';

export default function LoadingSpinner({ fullScreen }) {
  return (
    <div
      className={`relative flex justify-center items-center ${
        fullScreen ? 'h-screen mt-20' : ''
      }`}
    >
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-primary"></div>
      <div className="absolute top-0 h-full flex items-center justify-center">
        <img src={logo} alt={logo} />
      </div>
    </div>
  );
}
