import { MESSAGE_TYPES } from 'utils/constants';

const QuickBlox = require('quickblox/quickblox.min').QuickBlox;
const QB = new QuickBlox();

const APPLICATION_ID = '59533';
const AUTH_KEY = 'kfSs3tVXxG6cW8g';
const AUTH_SECRET = 'fMJ6Md9cTLd3KRP';
const ACCOUNT_KEY = 'C6VZBy9Mf2sEWqUDw19k';
export const QUICKBLOX_DEFAULT_PASSWORD = 'VanywhereQuickblox123456789';
const CONFIG = {
  endpoints: {
    api: 'apivanywhere.quickblox.com', // set custom API endpoint
    chat: 'chatvanywhere.quickblox.com',
  },
};

QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG);

export const registerToQuickBlox = ({
  email,
  firstName,
  lastName,
  callback,
}) => {
  QB.createSession(function (error, result) {
    if (error) {
      console.log({ error });
      return error;
    } else {
      const params = {
        email,
        password: QUICKBLOX_DEFAULT_PASSWORD,
        full_name: `${firstName} ${lastName}`,
      };
      return QB.users.create(params, callback);
    }
  });
};

export const loginToQB = ({ email, callback }) => {
  const params = { email, password: QUICKBLOX_DEFAULT_PASSWORD };
  return QB.createSession(params, callback);
};

export const sendMessage = async ({
  userVoipId,
  skillerVoipId,
  messageType,
  senderName,
  messagePrice,
  messageBody,
}) => {
  const userCredentials = {
    userId: userVoipId,
    password: QUICKBLOX_DEFAULT_PASSWORD,
  };

  return new Promise((resolve, reject) => {
    QB.getSession(function (error, session) {
      if (error) {
        console.log({ error });
      } else {
        try {
          QB.chat.connect(userCredentials, function (err, res) {
            try {
              const params = {
                type: 3,
                occupants_ids: [userVoipId, skillerVoipId],
              };
              console.log({ err });

              QB.chat.dialog.create(params, function (err, dialog) {
                if (err) {
                  console.log({ err });
                  reject(err);
                } else {
                  // const dialogJid = QB.chat.helpers.getRoomJidFromDialogId(
                  //   dialog._id
                  // );
                  const message = {
                    type: 'chat',
                    body: messageBody,
                    extension: {
                      type: '0',
                      save_to_history: 1,
                      dialog_id: dialog._id,
                      reply_type: MESSAGE_TYPES[messageType],
                      application_id: APPLICATION_ID,
                      chat_type: 'private',
                      senderName: senderName,
                      reply_price: messagePrice,
                      all_read: false,
                    },
                    markable: 1,
                  };
                  const opponentId = skillerVoipId;
                  try {
                    message.id = QB.chat.send(opponentId, message);
                    resolve({
                      dialogId: dialog._id,
                      messageId: message.id,
                    });
                  } catch (e) {
                    console.log({ e });
                  }
                }
              });
            } catch (error) {
              console.log(error);
            }
          }); // opens connections with ws
        } catch (e1) {
          console.log({ e1 });
        }
      }
    });
  });
};

export default QB;
