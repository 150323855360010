const ANDROID_APP_URL =
  "https://play.google.com/store/apps/details?id=com.vanywhere.skiller";
const IOS_APP_URL = "https://apps.apple.com/us/app/vanywhere/id1162055025";
const APP_PATHS = {
  SKILLER_PAGE: "/:nickname",
  ORDER_FORMS: "/:nickname/order",
};

const TERMS_AND_COND_LINK =
  "https://s3.amazonaws.com/vanywhere.com/Vanywhere+Terms+and+Conditions+%26+Privacy+Policy+21.pdf";

const MESSAGE_TYPES = {
  video: "0",
  voice: "1",
  text: "2",
};

export {
  ANDROID_APP_URL,
  IOS_APP_URL,
  APP_PATHS,
  TERMS_AND_COND_LINK,
  MESSAGE_TYPES,
};
