import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { APP_PATHS } from 'utils/constants';
import 'tailwindcss/tailwind.css';
import './App.css';
import Navbar from 'components/Navabr';
import SkillerPage from 'screens/SkillerScreen';
import OrderPage from 'screens/OrderScreen';
import { AuthProvider } from 'context/user-context';
import { FirebaseProvider } from 'context/firebase-context';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <FirebaseProvider>
        <AuthProvider>
          <Router>
            <Navbar />
            <Switch>
              {/* Order is important here otherwise order wont work */}
              <Route path={APP_PATHS.ORDER_FORMS} component={OrderPage} exact />
              <Route
                path={APP_PATHS.SKILLER_PAGE}
                component={SkillerPage}
                exact
              />
            </Switch>
          </Router>
        </AuthProvider>
      </FirebaseProvider>
    </QueryClientProvider>
  );
}

export default App;
