import { initializeApp } from 'firebase/app';
import { getDatabase, ref } from 'firebase/database';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Production
const firebaseConfig = {
  apiKey: 'AIzaSyDOBsWSTgoUMgcdUSnFklyTwhSu48wG40U',
  authDomain: 'vanywhere-d521e.firebaseapp.com',
  databaseURL: 'https://vanywhere-d521e.firebaseio.com',
  projectId: 'vanywhere-d521e',
  storageBucket: 'vanywhere-d521e.appspot.com',
  messagingSenderId: '990873844076',
  appId: '1:990873844076:web:6dceda79aa1edc4e1d1b49',
  measurementId: 'G-DQQC9TN7Z9',
};

// Development;
// const firebaseConfig = {
//   apiKey: 'AIzaSyDnxB57cObZoykQ0fdCqVmKqNCSqFAnsqA',
//   authDomain: 'vanywhere-dev.firebaseapp.com',
//   databaseURL: 'https://vanywhere-dev.firebaseio.com',
//   projectId: 'vanywhere-dev',
//   storageBucket: 'vanywhere-dev.appspot.com',
//   messagingSenderId: '1093576341376',
//   appId: '1:1093576341376:web:0e999d99b6e4d7aa893365',
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const dbRef = ref(database);
const functions = getFunctions(app);
const cloudFunctions = {
  createUser: httpsCallable(functions, 'createUser'),
  createGuestUser: httpsCallable(functions, 'createGuestUser'),
  getSkillerByNickName: httpsCallable(functions, 'getSkillerByNickName'),
  getSkillerByEmail: httpsCallable(functions, 'getSkillerByEmail'),
  getSecretKeys: httpsCallable(functions, 'getSecretKeys'),
  retriveStripeSavedPaymentMethods: httpsCallable(
    functions,
    'retriveStripeSavedPaymentMethods'
  ),
  retriveStripePaymentIntent: httpsCallable(
    functions,
    'retriveStripePaymentIntent'
  ),
  sendMessageToSkillerFromWeb: httpsCallable(
    functions,
    'sendMessageToSkillerFromWeb'
  ),
  fbPixelEvent: httpsCallable(functions, 'fbPixelEvent'),
};

export { database, dbRef, cloudFunctions };
