/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import LoginFormModal from 'components/LoginFormModal';
import fullLogo from 'assets/images/Logo/full_logo.svg';
import miniLogo from 'assets/images/Logo/mini_logo.svg';
import { ANDROID_APP_URL, IOS_APP_URL } from 'utils/constants.js';

import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { ReactComponent as MenuIcon } from 'assets/icons/hamburger_menu.svg';
import { useLocation, useHistory } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

export default function Navbar() {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const navigateToSkillerPage = () => {
    const { pathname } = location;
    const splittedPathname = pathname.split('/');
    const skillerNickname = splittedPathname[1];
    const skillerPageRoute = `/${skillerNickname}`;
    history.push(skillerPageRoute);
  };

  const menuItems = [
    {
      label: 'iOS App',
      href: IOS_APP_URL,
    },
    {
      label: 'Android App',
      href: ANDROID_APP_URL,
    },
    // {
    //   label: 'Login',
    //   action: () => {
    //     setIsLoginModalOpen(true);
    //   },
    // },
  ];

  return (
    <>
      <LoginFormModal
        open={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
      <Disclosure
        as="nav"
        className="bg-white  lg:px-12 shadow-none mt-4 md:mt-8"
      >
        {({ open }) => (
          <>
            <div className="mx-auto pr-4 pl-2 px-4 md:px-6 lg:px-8 md:mt-2">
              <div className="flex justify-between h-16 items-center">
                <div className="flex">
                  <div className="flex flex-shrink-0 cursor-pointer">
                    <img
                      src={isMobileOnly ? miniLogo : fullLogo}
                      className={isMobileOnly ? 'w-8' : 'w-36'}
                      onClick={navigateToSkillerPage}
                      alt="logo"
                    />
                  </div>
                </div>

                <div className="-mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <Menu as="div" className="hidden md:block ml-3 relative">
                  <div>
                    <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      <MenuIcon
                        className="block h-12 w-12"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {menuItems.map((item) => {
                        return (
                          <Menu.Item key={item.label}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className="text-gray-600  hover:bg-blue-light hover:text-gray-800  block px-4 py-2 text-sm text-gray-700"
                                onClick={item.action}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.label}
                              </a>
                            )}
                          </Menu.Item>
                        );
                      })}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {menuItems.map((item) => (
                  <a
                    key={item.label}
                    href="#"
                    className="
                      block pl-3 pr-4 py-2 border-l-4 border-blue-light text-base font-medium"
                    onClick={item.action}
                  >
                    {item.label}
                  </a>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
