import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { OrderFormTypes } from 'components/OrderServiceButtons/OrderServiceButtons';
import { isMobileOnly } from 'react-device-detect';
import { useSkiller } from 'utils/skiller';
import LoadingSpinner from 'components/LoadingSpinner';

function OrderDetailsForm({
  orderFormType,
  skillerTitle,
  className,
  orderData,
  setOrderData,
}) {
  const onSubmit = (data) => console.log(data);
  const { skiller, isLoading } = useSkiller();

  const [showSendToInput, setShowSendToInput] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [instructions, setInstructions] = useState(orderData.instructions);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const { expressCheckoutPrice, isExpressCheckoutEnabled } = skiller;

  const onInputChange = (e) => {
    setOrderData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCharCount = (ev) => {
    setInstructions(ev.target.value);
  };

  return (
    <form
      id="details-form"
      onSubmit={handleSubmit(onSubmit)}
      className={`w-full ${className}`}
    >
      <div className="w-full" style={{ minWidth: isMobileOnly ? 0 : 360 }}>
        <div className="w-full">
          <div>
            <h3 className="leading-6 text-base md:text-lg font-HelveticaBold md:mb-3 ">
              The {orderFormType} {orderFormType !== 'video' ? 'message' : ''}{' '}
              is for
            </h3>
            <div className="flex justify-between lg:mt-1.5 text-tiny w-4/5 md:w-2/3 xl:w-3/5">
              <div className="flex flex-row-reverse">
                <label htmlFor="gift-message" className="ml-3">
                  Gift for someone else
                </label>
                <input
                  type="radio"
                  required
                  id="gift-message"
                  defaultChecked
                  name="message"
                  value="gift-message"
                  {...register('message')}
                  onChange={(e) => {
                    setShowSendToInput(true);
                    onInputChange(e);
                  }}
                  className="focus:ring-offset-1 focus:border-white-light"
                />
              </div>

              <div className="flex flex-row-reverse">
                <label htmlFor="self-message" className="ml-3">
                  Myself
                </label>
                <input
                  type="radio"
                  required
                  name="message"
                  id="self-message"
                  value="self-message"
                  {...register('message')}
                  onChange={(e) => {
                    setShowSendToInput(false);
                    onInputChange(e);
                  }}
                  className="focus:ring-offset-1 focus:border-white-light"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <div>
            <div className="mt-4 md:mt-9 flex flex-row">
              <div className="flex flex-col w-2/5 md:w-44">
                <label className="text-sm md:text-base font-HelveticaBold">
                  From:
                </label>
                <input
                  id="from"
                  {...register('from', { required: true })}
                  required
                  name="from"
                  onChange={onInputChange}
                  type="text"
                  maxLength="30"
                  className="mt-1 focus:ring-offset-1 focus:border-blue-light md:max-w-44 max-h-16  border-2 border-white rounded-md text-grey-darkest bg-grey-lightest"
                />
              </div>
              {showSendToInput ? (
                <div className="flex flex-col justify-end ml-auto w-2/5 md:w-44">
                  <label className="text-sm md:text-base font-HelveticaBold">
                    To:
                  </label>
                  <input
                    id="to"
                    {...register('to')}
                    name="to"
                    onChange={onInputChange}
                    type="text"
                    maxLength="30"
                    required
                    className="focus:ring-offset-1 focus:border-blue-light max-w-32 lg:max-w-72 lg:max-h-16 border-2 border-white rounded-md text-grey-darkest bg-grey-lightest"
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="mt-6 md:mt-12">
            <label className="block text-sm lg:text-base font-HelveticaBold">
              Your message/instructions for {skillerTitle}:
            </label>
            <div className="mt-1">
              <textarea
                id="instructions"
                {...register('instructions')}
                rows="5"
                maxLength="300"
                required
                name="instructions"
                onChange={(e) => {
                  handleCharCount(e);
                  onInputChange(e);
                }}
                value={instructions}
                className="focus:ring-blue-light focus:border-blue-light block sm:text-sm border-2 border-white rounded-md text-grey-darkest bg-grey-lightest w-full"
              ></textarea>
            </div>
            <p className="mt-2 lg:mt-1.5 text-xs mb-5 lg:mb-6 font-HelveticaLight text-grey-light">
              {300 - instructions.length} characters remaining
            </p>
          </div>
        </div>

        <div className="w-full ">
          <div>
            <h3 className="leading-6 text-base md:text-lg font-HelveticaBold md:mb-3">
              Delivery
            </h3>
            <div className="flex text-sm">
              <div className="flex flex-row-reverse ">
                <label htmlFor="standard-delivery" className="ml-1">
                  <span>Standard (Free)</span>
                  {isMobileOnly && <br />}
                  <span className="text-xs text-grey-light">
                    {' '}
                    Free - up to 7 days
                  </span>
                </label>
                <input
                  id="standard-delivery"
                  value="standard-delivery"
                  {...register('delivery')}
                  type="radio"
                  defaultChecked
                  required
                  name="delivery"
                  onChange={onInputChange}
                />
              </div>
              {isExpressCheckoutEnabled ? (
                <div className="flex flex-row-reverse ml-20">
                  <label htmlFor="expressDelivery" className="ml-1">
                    Express (+${expressCheckoutPrice}){isMobileOnly && <br />}
                    <span className="text-xs text-grey-light">
                      {' '}
                      Get it in 24 hours or less
                    </span>
                  </label>
                  <input
                    id="expressDelivery"
                    value="expressDelivery"
                    {...register('delivery')}
                    type="radio"
                    required
                    name="delivery"
                    onChange={onInputChange}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <button type="submit" id="details-form-btn" hidden />
    </form>
  );
}

OrderDetailsForm.propTypes = {
  orderFormType: PropTypes.oneOf(Object.values(OrderFormTypes)).isRequired,
};

export default OrderDetailsForm;
