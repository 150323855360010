import React from 'react';
import PropTypes from 'prop-types';
import SkillerIntro from 'components/SkillerIntro';
import LoadingSpinner from 'components/LoadingSpinner';

export default function Page({ children, className, loading }) {
  return (
    <div className={`pt-12 h-full w-full ${className}`}>
      {loading ? <LoadingSpinner fullscreen /> : null}
      <div className={`h-full w-full ${loading ? 'hidden' : ''}`}>
        {children}
      </div>
    </div>
  );
}

function Header({ children, className, onLoad, mainScreen }) {
  if (children) {
    return <header className={className}>{children}</header>;
  }

  return (
    <header className="mx-8 lg:mx-40">
      <SkillerIntro onLoad={onLoad} mainScreen={mainScreen} />
    </header>
  );
}

function Main({ children, className }) {
  return (
    <main className={`font-HelveticaBold h-full ${className}`}>{children}</main>
  );
}

Page.Header = Header;
Page.Main = Main;

Page.propTypes = {
  children: PropTypes.node.isRequired,
};
