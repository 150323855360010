import { useSkiller } from 'utils/skiller';

function SkillerProfileImg({ className, onLoad }) {
  const { skiller } = useSkiller();
  const { profile_image_url } = skiller;

  return (
    <img
      className={`inline-block object-center object-cover h-12 w-12 md:h-16 md:w-16 lg:h-20 lg:w-20 rounded-full border-4 border-blue-light p-1 aspect-w-10 aspect-h-10 ${className}`}
      src={profile_image_url}
      alt="Skiller's profile"
      onLoad={onLoad}
    />
  );
}

export default SkillerProfileImg;
