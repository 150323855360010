import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Page from 'components/Page';
import OrderDetailsForm from 'screens/OrderScreen/partials/OrderDetailsForm';
import { useAuth } from 'context/user-context';
import AuthForm from 'screens/OrderScreen/partials/AuthForm';
import OrderServiceButtons from 'components/OrderServiceButtons';
import CardPayment from 'components/CardPayment';
import ThankYouPage from 'screens/OrderScreen/partials/ThankYouPage';
import { OrderSummary } from './partials/OrderSummary';
import { useSkiller, useSkillerDMPrices } from 'utils/skiller';
import { Transition } from '@headlessui/react';
import LoadingSpinner from 'components/LoadingSpinner';
import { TERMS_AND_COND_LINK } from 'utils/constants';
import { cloudFunctions } from 'services/firebase';
import { sendMessage } from 'services/quickblox';
import { formatMessage } from 'utils/formatMessage';
import { PIXEL_EVENTS, trackEvent } from 'services/analytics';
import { Helmet } from 'react-helmet';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_smPOa5R6Czj1s3F7hV4xLBjO'); // test
const stripePromise = loadStripe('pk_live_wRJSz1mRrYJ4J4DV1AQDQLBJ'); // prod

function OrderScreen() {
  const [user] = useAuth();
  const location = useLocation();
  const [orderFormType, setOrderFormType] = useState();
  const [isPaid, setIsPaid] = useState(false);
  const [error, setError] = useState();
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const { skiller, isLoading } = useSkiller();
  const [stripeKeys, setStripeKeys] = useState({});
  const [orderData, setOrderData] = useState({
    orderFormType: '',
    message: '',
    from: '',
    to: '',
    instructions: '',
    delivery: '',
  });
  const [orderTotalPrice, setOrderTotalPrice] = useState();

  useEffect(() => {
    cloudFunctions.getSecretKeys().then((data) => {
      setStripeKeys(data);
    });
  }, []);

  useEffect(() => {
    const urlQuery = qs.parse(location.search);
    setOrderFormType(urlQuery.orderform);
  }, [location]);

  const {
    videoPrice,
    voicePrice,
    textPrice,
    isLoading: arePricesLoading,
  } = useSkillerDMPrices();

  if (isLoading || arePricesLoading) {
    return <LoadingSpinner fullScreen />;
  }

  const dmPrices = { video: videoPrice, voice: voicePrice, text: textPrice };
  const {
    first_name: skillerFirstName,
    voip_id: skillerVoipId,
    id: skillerId,
  } = skiller;

  const { isLoggedIn, voipId, userName, userId } = user;
  let paymentFormRef;

  const submitPayment = () => {
    setError('');
    setIsProcessingRequest(true);

    const form = document.getElementById('details-form');
    const hiddenSubmitBtn = document.getElementById('details-form-btn');

    if (!form.checkValidity()) {
      hiddenSubmitBtn.click();
      setIsProcessingRequest(false);
      return;
    }

    paymentFormRef.dispatchEvent(new Event('submit', { bubbles: true }));
  };

  const onPayment = async (transactionId) => {
    try {
      setIsPaid(true);
      const { instructions, from, to, message } = orderData;

      const messageBody = formatMessage({
        message: instructions,
        from,
        to: message === 'self-message' ? 'Myself' : to,
      });

      const { dialogId, messageId } = await sendMessage({
        userVoipId: voipId,
        skillerVoipId,
        messageBody,
        messageType: orderFormType,
        senderName: userName,
        messagePrice: orderTotalPrice,
      });

      await cloudFunctions.sendMessageToSkillerFromWeb({
        userId,
        dialogId,
        messageId,
        skillerId,
        messageType: 2, // Type of msg send by user - 0 for video_call 1 for voice_call 2 for text_message. FOR NOW USE 2
        textMessage: messageBody,
        messageRequestType:
          orderFormType === 'video' ? 0 : orderFormType === 'voice' ? 1 : 2,
        stripeTransactionId: transactionId,
        userType: user.isGuest ? 0 : 1, // 0 for guest , 1 for user of type USER OR GUEST (Required for backend operations)
      });
      trackEvent({
        event: PIXEL_EVENTS.PURCHASE,
        properties: {
          currency: 'USD',
          value: dmPrices[orderFormType],
          content_type: 'product',
          content_ids: orderFormType,
          txnid: transactionId,
        },
        action: 'button-click',
      });
    } catch (err) {
      console.log('sending message error', err);
      onError();
    } finally {
      setIsProcessingRequest(false);
    }
  };

  const onError = (error) => {
    setError(error);
    setIsProcessingRequest(false);
  };

  const isCompleted = !isProcessingRequest && isPaid;

  const setPaymentFormRef = (formRef) => {
    paymentFormRef = formRef;
  };

  if (isCompleted) {
    return <ThankYouPage />;
  }

  return (
    <Page>
      <Helmet>
        <meta property="product:price:amount" content={videoPrice} />
        <meta property="product:price:currency" content="USD" />
      </Helmet>
      <div className="md:w-5/6 lg:w-11/12 xl:w-full">
        <Page.Header />
      </div>
      <Page.Main className="bg-grey-darkest pb-36 text-white mt-2 lg:mt-10 w-full flex font-HelveticaLight">
        <div className="mx-8 w-full">
          <div className="lg:px-32 w-10/12 lg:w-11/12 xl:w-full 3xl:w-10/12">
            <OrderServiceButtons
              className="flex flex-row justify-between mt-8 mb-2 md:my-12 w-full"
              orderFormType={orderFormType}
              minified
            />
            <OrderDetailsForm
              className="w-full md:w-8/12 xl:w-7/12 3xl:w-6/12 4xl:w-4/12"
              orderFormType={orderFormType}
              skillerTitle={skillerFirstName}
              orderData={orderData}
              setOrderData={setOrderData}
            />
            <div className="w-full md:w-4/5 lg:w-5/6 xl:w-8/12 4xl:w-6/12">
              <PromoteMessage />
              <AuthForm priceForAnalytics={dmPrices[orderFormType]} />
              <div className="w-full">
                <Transition
                  as="div"
                  show={isLoggedIn}
                  enter="transform transition duration-700"
                  enterFrom="opacity-0 "
                  enterTo="opacity-100 "
                  leave="transform duration-1000 transition ease-in-out"
                  leaveFrom="opacity-100 "
                  leaveTo="opacity-0 scale-95 "
                >
                  <div className="flex flex-col">
                    <Elements
                      stripe={stripePromise}
                      // clientSecret: stripeKeys.stripeSecretKeyTest,
                      options={{
                        clientSecret: stripeKeys.stripeSecretKey,
                      }}
                    >
                      <CardPayment
                        setFormRef={setPaymentFormRef}
                        setIsPaid={setIsPaid}
                        onPayment={onPayment}
                        onError={onError}
                        price={orderTotalPrice}
                      />
                      <pre style={{ color: 'red', whiteSpace: 'pre-wrap' }}>
                        {error}
                      </pre>
                    </Elements>
                    <OrderSummary
                      orderFormType={orderFormType}
                      className="mt-10"
                      orderData={orderData}
                      setTotalOrderPrice={setOrderTotalPrice}
                    />
                    <button
                      className="primary-btn w-3/5 mt-10 m-auto py-4 border-none font-HelveticaBold"
                      onClick={submitPayment}
                      disabled={isProcessingRequest}
                    >
                      {isProcessingRequest ? (
                        <div className="flex justify-center items-center">
                          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                        </div>
                      ) : (
                        <span>Book Now</span>
                      )}
                    </button>
                    <TermsAndCondFooter />
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </div>
      </Page.Main>
    </Page>
  );
}

export default OrderScreen;

const TermsAndCondFooter = () => {
  return (
    <div className="text-sm md:text-base text-grey-light mt-10 text-center">
      By Booking, you agree to{' '}
      <a
        className="text-blue-light"
        href={TERMS_AND_COND_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        Vanywhere’s Terms of Service
      </a>
      , including our{' '}
      <a
        className="text-blue-light"
        href={TERMS_AND_COND_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      .
    </div>
  );
};

const PromoteMessage = () => (
  <div className="my-4 md:mt-6 border-t border-b border-grey-dark w-full">
    <div className="mt-4">
      <h3 className="text-base md:text-xl font-HelveticaBold md:mt-6">
        Want to keep the conversation going?
      </h3>
      <p className="mt-1.5 md:mt-9 text-sm md:text-base font-HelveticaBold">
        Create a Vanywhere user, log in from the Vanywhere app, and continue the
        conversation in Vanywhere DMs.
      </p>
      <p className="text-tiny lg:text-tiny font-HelveticaLight mb-4 lg:mb-7 text-grey-lightest">
        We’re also waiving the 5% service fee for Vanywhere users!
      </p>
    </div>
  </div>
);
