import { cloudFunctions } from 'services/firebase';

export async function getSkillerByNickname({ skillerNickname }) {
  const result = await cloudFunctions.getSkillerByNickName({
    skillerNickName: skillerNickname,
  });

  console.log({ skillerResult: result.data.skillersInfo });
  return result.data.skillersInfo;
}
