import React from 'react';
import Page from 'components/Page';
import prayingHandsEmoji from 'assets/images/praying-hands-emoji.png';
import appStoreBadge from 'assets/images/app-store-badge.png';
import googleStoreBadge from 'assets/images/google-store-badge.png';
import { ANDROID_APP_URL, IOS_APP_URL } from 'utils/constants';

const applicationStoreButtonsConfig = {
  appStore: {
    url: IOS_APP_URL,
    src: appStoreBadge,
  },
  googleStore: {
    url: ANDROID_APP_URL,
    src: googleStoreBadge,
  },
};

function ThankYouPage() {
  return (
    <Page>
      <Page.Main className="flex flex-col items-center gap-5 text-center mt-4 md:mt-16">
        <div className="w-full flex flex-col items-center ">
          <h1 className="text-blue-light text-2xl md:text-5xl">
            Thank you for your purchase
          </h1>
          <h2 className="text-blue-light w-5/6  text-base md:text-2xl -mt-2">
            Your personalized request is on its way
          </h2>
        </div>
        <img
          className="h-16 md:h-24 mt-4"
          src={prayingHandsEmoji}
          alt="praying hands emoji"
        />
        <div className="w-4/5 lg:w-2/3 mt-4 flex flex-col items-center">
          <h3 className="text-xl font-HelveticaBold text-grey-darkest">
            Want to get the response in real time?
          </h3>
          <p className="font-HelveticaLight text-lg text-grey-light w-full md:w-3/4">
            Download the Vanywhere app from the AppStore or Google Play and log
            in to your account. You’ll get a notification on your phone as soon
            as your request is answered.
          </p>
        </div>
        <div className="flex items-center justify-center mb-20 mt-6 gap-5 w-2/3">
          {Object.values(applicationStoreButtonsConfig).map(({ url, src }) => (
            <a key={src} href={url} target="_blank" rel="noopener noreferrer">
              <img
                src={src}
                className="h-10 md:h-12"
                alt="download on app store ios/android"
              />
            </a>
          ))}
        </div>
      </Page.Main>
    </Page>
  );
}

export default ThankYouPage;
