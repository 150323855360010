import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { ReactComponent as AmexIcon } from 'assets/icons/payment/americanexpress-color.svg';
import { ReactComponent as DinersIcon } from 'assets/icons/payment/dinnerclub-color.svg';
import { ReactComponent as DiscoverIcon } from 'assets/icons/payment/discover-color.svg';
import { ReactComponent as MasteroIcon } from 'assets/icons/payment/maestro-color.svg';
import { ReactComponent as MasterCardIcon } from 'assets/icons/payment/mastercard-color.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/payment/visa-color.svg';
import { useAuth } from 'context/user-context';
import { useEffect, useState } from 'react';
import { cloudFunctions } from 'services/firebase';

const getCardIconByType = (type) => {
  switch (type) {
    case 'visa':
      return <VisaIcon />;
    case 'American Express':
      return <AmexIcon />;
    case 'Diners Club':
      return <DinersIcon />;
    case 'discover':
      return <DiscoverIcon />;
    case 'mastero':
      return <MasteroIcon />;
    case 'MasterCard':
      return <MasterCardIcon />;
    default:
      return null;
  }
};

export default function CardPayment({ setFormRef, price, onPayment, onError }) {
  const stripe = useStripe();
  const elements = useElements();
  const [user] = useAuth();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [useExistingCard, setUseExistingCard] = useState(false);

  useEffect(() => {
    cloudFunctions
      .retriveStripeSavedPaymentMethods({ userId: user.userId, type: 'card' })
      .then((res) => {
        console.log({ res });
        if (!!res.data.message) {
          return;
        }
        const { paymentMethods } = res.data;
        setPaymentMethods(paymentMethods.data);
      });
  }, [user.userId]);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // // Get a reference to a mounted CardElement. Elements knows how
      // // to find your CardElement because there can only ever be one of
      // // each type of element.

      const paymentIntent = await cloudFunctions.retriveStripePaymentIntent({
        userId: user.userId,
        amount: price,
      });

      const cardNumberElement = elements.getElement(CardNumberElement);

      // Use your card Element with other Stripe.js APIs
      let result;
      if (!useExistingCard) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumberElement,
        });
        // console.log({ paymentMethodId: paymentMethods[0].card });
        // console.log({ paymentMethod });

        if (error) {
          console.log('[payment method error]', error);
          onError(
            'Something went wrong with the payment, please validate your payment details and try again'
          );
          return;
        }

        result = await stripe.confirmCardPayment(
          paymentIntent.data.stripe_client_secret,
          {
            payment_method: paymentMethod.id,
          }
        );
        // console.log('[PaymentMethod]', paymentMethod);
      } else {
        // console.log(paymentMethods[0]);
        result = await stripe.confirmCardPayment(
          paymentIntent.data.stripe_client_secret,
          {
            payment_method: paymentMethods[0].id,
          }
        );
      }

      // console.log({ result });
      if (result.error) {
        onError(result.error.message);
      } else {
        onPayment(result.paymentIntent.id);
      }
    } catch (error) {
      console.log('[try/catch error]', error);
      onError(
        'Something went wrong with the payment, please validate your payment details and try again'
      );
    }
  };

  return (
    <form ref={(ref) => setFormRef(ref)} onSubmit={handleSubmit}>
      {paymentMethods.length > 0 ? (
        <div className="text-sm mb-4">
          <h3 className="leading-6 text-base md:text-lg font-HelveticaBold mb-1 md:mb-3">
            Payment
          </h3>
          <div className="mb-4 flex">
            <input
              type="radio"
              id="existing-card"
              name="payment-type"
              value="1"
              onChange={() => {
                setUseExistingCard(true);
              }}
            />
            <label htmlFor="existing-card" className="ml-2 flex">
              Use credit card ending ****{paymentMethods[0].card.last4}{' '}
              <span className="ml-2">
                {getCardIconByType(paymentMethods[0].card.brand)}
              </span>
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="new-card"
              name="payment-type"
              value="2"
              defaultChecked
              onChange={() => {
                setUseExistingCard(false);
              }}
            />
            <label htmlFor="new-card" className="ml-2">
              Use a different payment method
            </label>
          </div>
        </div>
      ) : null}

      {!useExistingCard ? (
        <div className="mt-2 border-t border-gray-200 bg-white pt-4 w-80 md:w-full pb-10 px-4 text-black rounded-lg">
          <div className="flex justify-between flex-wrap">
            <h2 className="text-sm font-medium">Card payment</h2>
            <div className="flex justify-between w-96 max-w-xs">
              <AmexIcon />
              <DinersIcon />
              <DiscoverIcon />
              <MasteroIcon />
              <MasterCardIcon />
              <VisaIcon />
            </div>
          </div>
          <div className="mt-6 grid grid-cols-4 gap-y-6 gap-x-4">
            <div className="col-span-4">
              <label
                htmlFor="card-number"
                className="block text-xs font-medium text-gray-700"
              >
                Card number
              </label>
              <div className="mt-1">
                <CardNumberElement
                  options={{
                    showIcon: true,
                    placeholder: '1234 5678 9012 3456',
                    classes: {
                      base: 'block w-full border-gray-300 rounded-md shadow-sm sm:text-sm',
                      focus: 'ring-indigo-500 border-indigo-500',
                    },
                  }}
                />
              </div>
            </div>

            <div className="col-span-2">
              <label
                htmlFor="expiration-date"
                className="block text-xs font-medium text-gray-700"
              >
                Expiry date
              </label>
              <div className="mt-1">
                <CardExpiryElement
                  options={{
                    classes: {
                      base: 'block w-full border-gray-300 rounded-md shadow-sm sm:text-sm',
                      focus: 'ring-indigo-500 border-indigo-500',
                    },
                  }}
                />
              </div>
            </div>

            <div className="col-span-2">
              <label
                htmlFor="cvc"
                className="block text-xs font-medium text-gray-700"
              >
                CVC / CVV
              </label>
              <div className="mt-1">
                <CardCvcElement
                  options={{
                    placeholder: '123',
                    classes: {
                      base: 'block w-full border-gray-300 rounded-md shadow-sm sm:text-sm',
                      focus: 'ring-indigo-500 border-indigo-500',
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </form>
  );
}
