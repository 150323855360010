import { cloudFunctions } from 'services/firebase';

export const PIXEL_EVENTS = Object.freeze({
  VIEW_CONTENT: 'ViewContent',
  ADD_TO_CART: 'AddToCart',
  INITIATE_CHECKOUT: 'InitiateCheckout',
  PURCHASE: 'Purchase',
});

export const GA_EVENTS = Object.freeze({
  ViewContent: 'view_item',
  AddToCart: 'add_to_cart',
  InitiateCheckout: 'begin_checkout',
  Purchase: 'purchase',
});

export function trackEvent({ event, properties }) {
  window.fbq('track', event, properties); // facebook pixel
  window.gtag('event', GA_EVENTS[event]); // google analytics
  cloudFunctions.fbPixelEvent({
    eventName: event,
    sourceUrl: window.location.href,
    amount: properties?.value,
    txnid: properties?.txnid,
  });
}
