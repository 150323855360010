import { useParams } from 'react-router-dom';
import { getSkillerByNickname } from 'api/skiller';
import { useQuery } from 'react-query';
import _ from 'lodash';

const fetchSkillerProfileConfig = (skillerNickname) => ({
  queryKey: ['skiller', { skillerNickname }],
  queryFn: () => getSkillerByNickname({ skillerNickname }),
});

export function useSkiller() {
  const { nickname: skillerNickname } = useParams();
  const result = useQuery(fetchSkillerProfileConfig(skillerNickname));
  return {
    ...result,
    skiller: {
      ...result.data,
    },
  };
}

export const useSkillerDMPrices = () => {
  const { skiller, isLoading } = useSkiller();

  let textPrice, videoPrice, voicePrice;
  if (!isLoading && !_.isNil(skiller) && !_.isEmpty(skiller)) {
    ({
      skiller_information: {
        dm_price: {
          text_dm_price: textPrice,
          video_dm_price: videoPrice,
          voice_dm_price: voicePrice,
        },
      },
    } = skiller);
  }
  return { textPrice, videoPrice, voicePrice, isLoading };
};
